import React from "react";
import {
    StructuredListBody,
    StructuredListCell,
    StructuredListRow,
    StructuredListWrapper
} from 'carbon-components-react/lib/components/StructuredList';

import './mlops.css'

import {
    Button, TextArea, TextInput, Select, SelectItem, FileUploader, FileUploaderItem,
    Link,
    Accordion,
    AccordionItem
} from "@carbon/react";


import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Dropdown } from "carbon-components-react";
import TrashCan from "@carbon/icons-react/lib/trash-can/20";
import { loggedInUserId, mlp_get_options, SERVER_API_URL } from "../index";

export default class UploadModel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            invalidName: false,
            invalidDescription: false,
            invalidVersion: false,
            invalidLearnerName: false,
            model: {
                name: "",
                format: "",
                description: "",
                version: "",
                target: "",
                metrics: {
                    type: "test",
                    measurements: {}
                },
                learner: {
                    name: "",
                    label_encodings: [],
                    problem_type: "regression"
                },
                input_schema: {
                    fields: []
                },
                runtime: "py3.11-default"
            },
            selectedFile: undefined
        }

        this.desc = React.createRef("");
        this.ver = React.createRef("0.0.1");
        this.target = React.createRef("");
        this.name = React.createRef("");
        this.labelEncodings = React.createRef([]);
        this.learnerName = React.createRef("");
        this.labelOutput = React.createRef("");
        this.schemaFieldName = React.createRef("");
        this.schemaFieldType = "string";
    }

    version = () => {
        const v = this.state.ver;
        if (v == undefined || v === "") {
            return "0.0.1"
        }
        return v;
    }

    onSelectModelType = (e) => {
        let m = this.state.model;
        m.learner.problem_type = e.selectedItem.id;
        this.setState({
            model: m
        })

    }

    onSelectSchemaFieldType = (e) => {
        this.schemaFieldType = e.selectedItem.id;
    }

    saveLabel = (e) => {
        if (e.key == "Enter") {
            let m = this.state.model;
            m.learner.label_encodings.push(this.labelOutput.current.value)
            this.labelOutput.current.value = ""
            this.setState({
                model: m,
            });
        }
    }

    saveSchemaField = () => {
        let n = this.schemaFieldName.current.value
        if (n !== "") {
            let m = this.state.model;
            let fields = m.input_schema.fields;
            fields.push({
                name: n,
                data_type: this.schemaFieldType
            })

            this.setState({
                model: m
            })
        }
    }

    saveSchemaFieldKey = (e) => {
        if (e.key == "Enter") {
            this.saveSchemaField()
        }
    }

    deleteLabel = (idx) => {
        let m = this.state.model
        m.learner.label_encodings.splice(idx, 1);
        this.setState({
            model: m
        });
    }

    renderLabelEncodings = () => {
        let rows = [];

        var i = 0;
        for (const value of this.state.model.learner.label_encodings) {
            const k = i;
            rows.push(<TableRow key={i}>
                <TableCell>{k}</TableCell>
                <TableCell>{value}</TableCell>
                <TableCell><Link onClick={() => this.deleteLabel(k)}><TrashCan /></Link></TableCell>
            </TableRow>);
            i += 1;
        };

        return rows;
    }

    classification = () => {

        if (this.state.model.learner.problem_type == 'classification') {
            return <div className="mlp_labels_table">
                <TextInput
                    labelText=""
                    id="label_output"
                    type="text"
                    onKeyPress={this.saveLabel}
                    ref={this.labelOutput}
                />
                <div className="mlp_label_encodings_list">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeader>Class</TableHeader>
                                <TableHeader>Output label</TableHeader>
                                <TableHeader></TableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.renderLabelEncodings()
                            }
                        </TableBody>
                    </Table>
                </div>
            </div>

        }
        return <div />
    }

    handleFilesChange = (event) => {
        let sel = event.target.files[0]
        this.setState({
            selectedFile: {
                name: sel.name,
                content: sel
            }
        });
    }

    validate = () => {


        this.state.invalidName = this.name.current.value === "";
        this.state.invalidDescription = this.desc.current.value === "";
        this.state.invalidVersion = this.ver.current.value === "";
        this.state.invalidLearnerName = this.learnerName.current.value === "";


        this.setState({});
        return !(this.state.invalidName ||
            this.state.invalidDescription ||
            this.state.invalidVersion ||
            this.state.invalidLearnerName ||
            this.state.selectedFile === undefined
        )
    }

    onOk = () => {
        if (!this.validate()) {
            return
        }

        this.state.model.learner.name = this.learnerName.current.value;
        this.state.model.name = this.name.current.value;
        this.state.model.description = this.desc.current.value;

        this.state.model.version = this.ver.current.value;
        this.state.model.format = (this.state.selectedFile !== undefined) ? this.state.selectedFile.name.split('.').pop() : "zip";
        this.state.model.target = this.target.current.value;

        console.log(this.state.model)
        let formData = new FormData();
        formData.append("binary", this.state.selectedFile.content);
        formData.append("meta", JSON.stringify(this.state.model));
        const pathToFetch = SERVER_API_URL + '/v1/models'
        mlp_get_options(pathToFetch, { method: "POST", body: formData })
            .then(jsonData => {
                this.props.onUpload(jsonData)
            })
    }

    deleteSchemaField = (idx) => {
        let m = this.state.model
        m.input_schema.fields.splice(idx, 1);
        this.setState({
            model: m
        });

    }

    renderUploader = () => {

        return <div className="mlp_model_uploader">

            <FileUploader
                key={"uploader"}
                onChange={this.handleFilesChange}
                accept={[
                    '.zip',
                    '.pkl',
                    '.joblib'
                ]}
                filenameStatus="edit"
                buttonKind="secondary"
                buttonLabel="Select model file"
                iconDescription="Clear file"
                labelTitle="Upload model"
            />
            {
                (this.state.selectedFile === undefined) ? <div className="mlp_model_file_error">Model file is required</div> : <div />
            }
        </div>
    }

    render() {

        return <div className="mlp_ops_exp_panel2">
            <StructuredListWrapper>
                <StructuredListBody>

                    <StructuredListRow>
                        <StructuredListCell className='mlp_left_cells'>
                            <div className="text_field">
                                <TextInput
                                    id="model_name"
                                    key="model_name"
                                    labelText="Name"
                                    type="text"
                                    invalid={this.state.invalidName}
                                    invalidText={"Required"}
                                    ref={this.name}
                                />
                            </div>
                            <div className="text_field">
                                <TextInput
                                    id="model_ver"
                                    key="model_ver"
                                    labelText="Version"
                                    type="text"
                                    invalid={this.state.invalidVersion}
                                    invalidText={"Required"}
                                    ref={this.ver}
                                />
                            </div>
                            <div className="text_field">
                                <TextInput
                                    id="model_target"
                                    key="model_target"
                                    labelText="Target"
                                    type="text"
                                    ref={this.target}
                                />
                            </div>
                            <div className="select">
                                <div className="text_field">
                                    <TextInput
                                        id="learner_name"
                                        key="learner_name"
                                        labelText="Learner name"
                                        invalid={this.state.invalidLearnerName}
                                        invalidText={"Required"}
                                        type="text"
                                        ref={this.learnerName}
                                    />
                                </div>

                            </div>
                            {
                                this.renderUploader()
                            }
                        </StructuredListCell>
                        <StructuredListCell className='mlp_left_cells'>
                            <div className="text_field">
                                <TextArea
                                    id="model_desc"
                                    key="model_desc"
                                    labelText="Description"
                                    invalid={this.state.invalidDescription}
                                    invalidText={"Required"}
                                    type="text"
                                    ref={this.desc}
                                />
                            </div>
                            <div className="text_field">
                                <Dropdown
                                    label="Regression"
                                    id="model_types"
                                    key="model_types"
                                    onChange={this.onSelectModelType}
                                    items={[
                                        {
                                            id: 'regression',
                                            label: 'Regression'
                                        },
                                        {
                                            id: 'classification',
                                            label: 'Classification',
                                        },
                                        {
                                            id: 'timeseries',
                                            label: 'Time series',
                                        }
                                    ]}
                                    titleText="Model type"

                                />
                            </div>
                            {
                                this.classification()
                            }
                            <div className="mlp_upload_schema_container">
                                <Accordion>
                                    <AccordionItem title="Schema" open={false}>
                                        <div className="text_field mlp_schema_field_name">
                                            <TextInput
                                                id="schema_field_name"
                                                key="schema_field_name"
                                                labelText="Field name"
                                                type="text"
                                                onKeyPress={this.saveSchemaFieldKey}
                                                ref={this.schemaFieldName}
                                            />
                                        </div>
                                        <div className="text_field mlp_schema_field_type">
                                            <Dropdown
                                                onChange={this.onSelectSchemaFieldType}
                                                label="String"
                                                id="model_types"
                                                key="model_types"
                                                ref={this.schemaFieldType}
                                                items={[
                                                    {
                                                        id: 'string',
                                                        label: 'String'
                                                    },
                                                    {
                                                        id: 'float',
                                                        label: 'Float',
                                                    },
                                                    {
                                                        id: 'int',
                                                        label: 'Int',
                                                    },
                                                    {
                                                        id: 'bool',
                                                        label: 'Bool',
                                                    },
                                                    {
                                                        id: 'datetime',
                                                        label: 'Datetime',
                                                    }
                                                ]}
                                                titleText="Field type"

                                            />
                                        </div>
                                        <div className="text_field mlp_schema_add_field">
                                            <Button size="md" kind={"secondary"} onClick={this.saveSchemaField}>Add field</Button>
                                        </div>

                                        <div className="mlp_schema_table">
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableHeader>Field name</TableHeader>
                                                        <TableHeader>Field type</TableHeader>
                                                        <TableHeader></TableHeader>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        this.state.model.input_schema.fields.map((e, index) => <TableRow>
                                                            <TableCell>{e.name}</TableCell>
                                                            <TableCell>{e.data_type}</TableCell>
                                                            <TableCell><Link onClick={() => (this.deleteSchemaField(index))}><TrashCan /></Link></TableCell>
                                                        </TableRow>)
                                                    }
                                                </TableBody>
                                            </Table>
                                        </div>

                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </StructuredListCell>

                    </StructuredListRow>

                </StructuredListBody>
            </StructuredListWrapper>
            <div className="mlp_save_cancel_buttons">
                <Button kind={"primary"} onClick={this.onOk}><p>Save</p></Button>
                <Button kind={"secondary"} onClick={this.props.close}><p>Cancel</p></Button>
            </div>
        </div>

    }
}