import React from "react";
import './churn.css'
import Datasets from "../datasets/DataSets";
import MLPTextWithMore from "../components/TextWithMore";

export default class MLPChurnDataSets extends React.Component {

    render() {
        return (
            <div className={"mlp_churn_dataset_description"}>
                <div className={"mlp_datasets_header"}>
                    <br/>
                    <h3 className="mlp_size_xlarge mlp_no_padding">Choose or define a data set for Churn Analysis journey</h3>
                    <br/><br/>
                    <div className={"mlp_left"}>
                        <MLPTextWithMore
                            above={<h6>You can select a predefined dataset or define your dataset in order perform
                                insight analysis. Expand to see how your data should look like in order to have a successful
                                churn insight.</h6>}>
                            <div className="mlp_left">It is recommended to have a data with at least the following roles
                                of the fields from the selected datasource:
                                <br/>
                                <li><b className={"mlp_italic_text"}>Customer Identifier</b> - field used to identify
                                    customers
                                </li>
                                <li><b className={"mlp_italic_text"}>Transaction Identifier</b> - field used to identify
                                    customers purchase transactions
                                </li>
                                <li><b className={"mlp_italic_text"}>Transaction Time</b> - field used to identify
                                    customers
                                    purchase transaction time
                                </li>
                                <li><b className={"mlp_italic_text"}>Product</b> - field used to identify product that
                                    was
                                    purchased
                                </li>
                                <li><b className={"mlp_italic_text"}>Quantity</b> - field used to identify purchased
                                    quantity
                                </li>
                                <li><b className={"mlp_italic_text"}>Price</b> - field used to identify transaction
                                    price
                                </li>
                            </div>
                        </MLPTextWithMore></div>
                    <br/>
                    <Datasets onDataSet={this.props.onDataSet} hideControls={true}/>
                </div>
            </div>
        )
    }
}