import React from "react";
import {Button, ComposedModal, Link, Loading, Modal, ModalBody, ModalHeader} from "@carbon/react";
import {
    isLoggedInUser,
    mlp_get,
    mlp_get_options,
    mlp_post,
    niceDate,
    niceTimestamp,
    SERVER_API_URL,
    trimTo
} from "../index";
import Analytics from "@carbon/icons-react/lib/analytics/20";
import List from "@carbon/icons-react/lib/list/20"
import TrashCan from "@carbon/icons-react/lib/trash-can/20";
import Share from "@carbon/icons-react/lib/share/20";
import ShareKnowledge from "@carbon/icons-react/lib/share-knowledge/20";
import {DataTable, Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "carbon-components-react";
import {render} from 'react-dom';
import MLPGoogleAnalytics from "../components/GA";
import ShareTo from "../components/ShareTo";
import ViewChurn from "../churn/ViewChurn";
import ViewRisk from "../risk/ViewRisk";
import ViewSentiment from "../sentiments/ViewSentiment";
import {getUser} from "../common/utils";

export default class MLPUseCases extends React.Component {

    useCaseHeaderData = [
        {
            key: 'name',
            header: 'Name',
        },
        {
            key: 'author',
            header: 'Author',
        },
        {
            key: 'type',
            header: 'Type of Problem',
        },
        {
            key: 'date',
            header: 'Creation Date',
        },
        {
            key: 'status',
            header: 'Computing Status',
        },
        {
            key: 'action',
            header: '',
        }]

    ga = new MLPGoogleAnalytics();

    constructor(props, context) {
        super(props, context);
        this.fetchDatasets = this.fetchDatasets.bind(this);
        this.fetchJobs = this.fetchJobs.bind(this);
        this.deleteJob = this.deleteJob.bind(this);
        this.caseLogs = this.caseLogs.bind(this);

        this.state = {
            datasets: null,
            jobs: null,
            showShareTo: undefined,
            deleteJob: undefined,
            user: getUser()
        };

        this.isUserLogged = isLoggedInUser()
        this.fetchDatasets().then(this.fetchJobs)

    }


    fetchDatasets() {
        const pathToFetch = SERVER_API_URL + '/v1/datasets'
        return mlp_get(pathToFetch)
            .then(jsonData => {
                //-- await new Promise(r => setTimeout(r, 1000));
                this.setState({datasets: jsonData});
            })
    }

    countJobsRunning(filteredJobs) {
        return filteredJobs.filter(job => job.system.status === 'running' || job.system.status === 'pending').length
    }

    fetchJobs() {
        const pathToFetch = SERVER_API_URL + '/v1/jobs'
        return mlp_get(pathToFetch)
            .then(jsonData => {
                const filteredJobs = this.filterJobs(jsonData).reverse()
                //-- await new Promise(r => setTimeout(r, 1000));
                if (this.countJobsRunning(filteredJobs) > 0) setTimeout(this.fetchJobs, 5000)
                this.setState({
                    jobs: filteredJobs,
                    deleteJob: undefined
                });
            });
    }

    deleteJob() {
        const pathToFetch = SERVER_API_URL + '/v1/jobs/' + this.state.deleteJob
        mlp_get_options(pathToFetch, {method: 'DELETE'}).then(this.fetchJobs);
        return false;
    }

    share(jobId) {
        this.setState({
            showShareTo: jobId
        })
        return false;
    }

    caseLogs(jobId) {
        const MsgWindow = (props) => (
            <div className={"mlp_case_logs_holder mlp_ontop"}>
                <ComposedModal size={"sm"} open>
                    <ModalHeader title="Deep Insights - journey logging"/>
                    <ModalBody hasScrollingContent>
                        <b>{props.subtitle}</b>
                    </ModalBody>
                </ComposedModal>
            </div>
        );
        const pathToFetch = SERVER_API_URL + '/v1/jobs/' + jobId + '/statuses'
        mlp_get(pathToFetch)
            .then(jsonData => {
                const messages = jsonData.map(s => {
                    return (<div className={"mlp_grid_logs"}>
                        <div>{niceTimestamp(s.timestamp)}</div>
                        <div>{s.text == undefined ? s.phase : trimTo(40, s.text)}</div>
                        <div>{s.status}</div>
                    </div>)
                })
                render(<MsgWindow subtitle={(<div
                    className={"mlp_larger_msg"}>{messages}</div>)}/>, document.getElementById("logs_of_" + jobId));
            })

        return false;
    }

    _jobScenario(job) {
        switch (job.object.class) {
            case "customer-churn":
                return (<h6>Churn Analysis</h6>);
            case "risk":
                return (<h6>Risk Analysis</h6>);
            case "sentiments":
                return (<h6>Sentiments</h6>);
            default:
                return ("Custom case")
        }
    }


    filterJobs(jobs) {
        const jobsRows = jobs.data.map(job => {
            job.id = job.system.id;
            job.name = job.object.name
            job.type = this._jobScenario(job)
            job.author = job.system.author
            job.date = job.system.created_at
            job.status = job.system.status
            return job
        })
        return jobsRows
    }

    _renderStatus(cell) {
        if (cell.value === "failed") {
            return cell.value //TBD - we need to put a tooltip with the failed reason
        } else return cell.value
    }

    _showJobViewLink(row) {
        const viewClick = (isDemo) => {
            const status = row.cells[4].value
            if (status === "failed") {
                return this.caseLogs(row.id)
            } else {
                let jobType = "churn";
                const problemType = row.cells[2].value.props.children
                if (problemType.includes("Risk")) {
                    jobType = "risk"
                } else if (problemType.includes("Sentiments")) {
                    jobType = "sentiments"
                }

                this.ga.viewScenario((isDemo ? 'Demo ' : 'Own ') + jobType)
                this.props.onJobView(row.id, jobType)
            }
            return true;
        }
        const author = row.cells[1].value
        if (author === "") {
            return (
                <div className={"mlp_main_demo_btn"}><Button onClick={() => viewClick(true)}>View Demo</Button></div>)
        } else return (<Link className={"mlp_center"}
                             onClick={() => viewClick(false)}><Analytics/></Link>)
    }

    _showJobLogsLink(row) {
        const author = row.cells[1].value
        if (author === "") {
            return (<div/>)
        } else return (<Link className={"mlp_center"}
                             onClick={() => {
                                 return this.caseLogs(row.id)
                             }}><List/></Link>)
    }

    _shareJobLink(row) {
        const author = row.cells[1].value
        if (author === "") {
            return (<div/>)
        } else return (<Link className={"mlp_center"}
                             onClick={() => {
                                 return this.share(row.id)
                             }}><Share/></Link>)
    }

    _showJobDelLink(row) {
        const author = row.cells[1].value
        if (author === "") {
            return (<div/>)
        } else return (<Link disabled={this.isUserLogged === false} className={"mlp_center"}
                             onClick={() => {
                                 this.setState({deleteJob: row.id, deleteJobName: row.cells[0].value})
                                 return false
                             }}><TrashCan/></Link>)
    }

    _renderUseCase(row, cell) {
        if (cell.id.endsWith(":action")) {
            return (
                <div className={"mlp_grid_1_2_3_4"}>
                    <div title={"View Details"}>{this._showJobViewLink(row)}</div>
                    <div title={"Case Logs"}>{this._showJobLogsLink(row)}</div>
                    <div title={"Share"}>{this._shareJobLink(row)}</div>
                    <div title={"Delete Case"}>{this._showJobDelLink(row)}</div>
                </div>)
        } else if (cell.id.endsWith(":date")) {
            return (<div>{niceDate(cell.value)}</div>)
        } else if (cell.id.endsWith(":author")) {
            let displayValue = cell.value
            if (displayValue === "") {
                displayValue = "demo"
            }
            if (displayValue != this.state.user) {
                return <div><ShareKnowledge/><span className="mlp-jobs-author-name">{displayValue}</span></div>
            }
            return (<div>{displayValue}</div>)
        } else if (cell.id.endsWith(":status")) {
            return (<div>{this._renderStatus(cell)}</div>)
        } else return (<div>{cell.value}</div>)
    }

    saveSharing = (id, object) => {
        const payload = [
            object,
        ];
        mlp_post(SERVER_API_URL + '/v1/jobs/' + id + "/sharing", payload)
            .then(jsonData => {
                if (jsonData.object !== undefined) {
                    this.setState({showShareTo: undefined})
                }
            });


    }

    showShareToModal() {
        if (this.state.showShareTo !== undefined) {
            return <ShareTo
                open={true}
                close={() => this.setState({showShareTo: undefined})}
                title="Share job"
                labels={["Manage", "Read"]}
                names={["manage", "read"]}
                sharing={
                    {
                        "id": this.state.showShareTo,
                        "user": "",
                        "permissions": []
                    }
                }
                save={this.saveSharing}
            />
        }
        return <div/>
    }

    renderJobsTable() {
        if (this.state.jobs === null) {
            return (
                <div className={"mlp_center"}><Loading description="loading journeys" small withOverlay={false}/></div>)
        } else if (this.state.jobs.length === 0) {
            return (<h6>this insight has no jobs run yet</h6>)
        } else return (
            <div>
                <DataTable rows={this.state.jobs} headers={this.useCaseHeaderData}>
                    {({rows, headers, getHeaderProps, getTableProps}) => (
                        <Table {...getTableProps()}>
                            <TableHead>
                                <TableRow>
                                    {headers.map((header) => (
                                        <TableHeader {...getHeaderProps({header})}>
                                            <div
                                                className={"mlp_main_usecase_field_" + header.key}>{header.header}</div>
                                        </TableHeader>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={"use_case_row_" + row.id}>
                                        {row.cells.map((cell) => {
                                            return <TableCell
                                                key={"use_case_of_" + cell.id}>
                                                <div className={"mlp_absolute"} id={"logs_of_" + row.id}/>
                                                {this._renderUseCase(row, cell)}</TableCell>
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </DataTable>
                {
                    this.showShareToModal()
                }

                <Modal
                    modalHeading={"Are you sure you want to delete " +
                        trimTo(16,this.state.deleteJobName != undefined ? this.state.deleteJobName : "this") +
                        " case analysis ?"}
                    open={this.state.deleteJob !== undefined}
                    size="xs"
                    onRequestClose={e => this.setState({deleteJob: undefined, deleteJobName: undefined})}
                    onRequestSubmit={this.deleteJob}
                    primaryButtonText="Yes"
                    secondaryButtonText="No"
                    alert={true}
                    danger={true}
                ></Modal>
            </div>
        )
    }

    closeJobView = () => {
        this.props.onJobView(undefined, undefined)
    }

    render() {
        if (this.props.viewJobId !== undefined) {
            switch (this.props.viewJobType) {
                case "churn":
                    return <ViewChurn
                        id={this.props.viewJobId}
                        onClose={this.closeJobView}
                    />
                case "risk":
                    return <ViewRisk
                        id={this.props.viewJobId}
                        onClose={this.closeJobView}/>
                case "sentiments":
                    return <ViewSentiment
                        id={this.props.viewJobId}
                        onClose={this.closeJobView}/>
            }
        }

        if (this.state.jobs === null) {
            return (<div className={"mlp_center"}><Loading description="loading use cases" withOverlay={false}/></div>)
        } else if (this.state.jobs.length === 0) {
            return (
                <h4 className={"mlp_main_nocase"}> You do not have any insights defined yet.<br/> Select a scenario and
                    start creating your data insights.</h4>);
        } else {
            return (this.renderJobsTable())
        }

    }
}
