import React from "react";

import {Button, Checkbox, Tab, TabList, TabPanel, TabPanels, Tabs} from '@carbon/react';

import "../churn/churn.css"
import "@carbon/charts/styles.css";

import {InlineLoading} from "carbon-components-react";
import {isLoggedInUser, mlp_get, mlp_get_options, SERVER_API_URL} from "../index";
import {displayWarnAction} from "../components/Notifications";


export default class MLPSentimentsPredict extends React.Component {


    constructor(props, context) {
        super(props, context);

        this.monitorModelDeployment = this.monitorModelDeployment.bind(this);
        this.monitorModelDeployment()
        this.monitorModels = false
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    monitorModelDeployment(count = 0) {
        const pathToFetch = SERVER_API_URL + '/v1/models?object.job_id=' + this.props.meta.jobId()
        mlp_get(pathToFetch)
            .then(async jsonData => {
                this.props.meta.setModels(jsonData.data)
                this.setState({
                    renderModels: true
                })
                this.props.meta.getModels().map(model => {
                    if (this.monitorModels === false && model.system.status === "pending" && model.object.prediction_info.enabled === true && count < 100) {
                        this.monitorModels = true
                        return new Promise(r => setTimeout(r, 3000)).then(r => {
                                this.monitorModels = false
                                this.monitorModelDeployment(count + 1)
                            }
                        )
                    }
                })
            })
    }


    _renderModelsInfo() {
        const models = this.props.meta.getModels()
        console.log("models: " + models.length)
        if (models.length > 0) {
            return (
                <div className={"mlp_padding_1rem"}>
                    <div className={"mlp_pred_models_parent"}>
                        <div>
                            <div className={"mlp_pred_models_holder mlp_header_colors"}>
                                <div>&nbsp;</div>
                                <div>Model Name</div>
                                <div>Description</div>
                            </div>

                            {models.map(model => {
                                return (<div key={model.system.id} className={"mlp_pred_models_holder"}>
                                    <Checkbox id={"check-" + model.system.id} checked={true} disabled={true}
                                              labelText=""/>
                                    <h6>{model.object.name}</h6>
                                    <div>BERT text analysis</div>
                                    {this.modelDeploymentStatus(model)}
                                </div>)
                            })}</div>
                        <div className={"mlp_pred_description"}>After building a prediction and reviewing its scorecard,
                            you decide whether to enable it.
                            Deep Insights gives you a certain number of total predictions and enabled
                            predictions.
                            Disabling predictions frees up space and saves prediction settings for later use.
                        </div>
                    </div>
                </div>
            )
        } else
            return (<div/>)
    }

    modelDeploymentStatus(model) {
        if (model.system.status === "pending") {
            if (model.object.prediction_info.enabled === true) {
                return (<div className={"mlp_deploy_btn"}><InlineLoading
                    description={"Enabling predictions..."}></InlineLoading>
                </div>)
            } else {
                return isLoggedInUser() ? (
                    <div className={"mlp_deploy_btn"}>
                        <Button disabled={false} kind={"primary"} size={"sm"} onClick={() => this.deployModel(model)}>
                            <p>Enable predictions</p>
                        </Button></div>) : (<h6>Disabled</h6>)
            }
        } else if (model.system.status === "ready") {
            if (model.object.prediction_info.enabled === false) {
                return (<h6>Disabled</h6>)
            } else {
                return isLoggedInUser() ? (
                    <div className={"mlp_deploy_btn"}>
                        <Button disabled={false} kind={"secondary"} size={"sm"}
                                onClick={() => this.undeployModel(model)}>
                            <p>Disable predictions</p>
                        </Button></div>) : (<h6>Disabled</h6>)
            }
        } else {
            return (<h6>Disabled</h6>)
        }
    }

    deployModel(model) {
        if (this.props.meta.isJobRunning()) {
            displayWarnAction("Please allow more time for this analysis in order to be able to use predictions...")
            return;
        }
        const deployPayload = [
            {"op": "replace", "path": "/prediction_info/path", "value": model.system.id},
            {"op": "replace", "path": "/prediction_info/enabled", "value": true},
            {
                "op": "replace", "path": "/prediction_info/lifetime", "value": {
                    "value": 5,
                    "unit": "minutes"
                }
            }
        ]
        return this._patchModel(deployPayload, model.system.id).then(r => this.monitorModelDeployment()).then(r => {
            this.setState({
                renderModels: false
            })
        })
    }

    _patchModel(deployPayload, modelId) {
        const pathToFetch = SERVER_API_URL + '/v1/models/' + modelId
        return mlp_get_options(pathToFetch, {method: 'PATCH', body: JSON.stringify(deployPayload)})
            .then(jsonData => {
                return jsonData
            })
    }

    render() {
        return (
            <div className={"mlp_churn_tabs_holder"}>
                <Tabs>
                    <TabList aria-label="List of tabs" light={true} iconSize={"lg"}
                             className={"mlp_churn_tabs_list_holder"}>
                        <Tab disabled className={"mlp_churn_tab_holder"}>1. Setup</Tab>
                        <Tab disabled className={"mlp_churn_tab_holder"}>2. Run</Tab>
                        {/*<Tab className={"mlp_churn_tab_holder"}>3. Predict</Tab>*/}
                    </TabList>
                    <TabPanels>
                        <TabPanel/>
                        <TabPanel/>
                        <TabPanel>
                            <div className={"mlp_sentiment_predict_holder"}>
                                {this._renderModelsInfo()}
                            </div>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
        )
    }
}