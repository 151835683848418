import React, { Fragment } from "react";
import {
    StructuredListBody,
    StructuredListCell,
    StructuredListRow,
    StructuredListWrapper
} from 'carbon-components-react/lib/components/StructuredList';

import './mlops.css'

import { TextArea, TextInput } from "@carbon/react";

import { Button, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "carbon-components-react";
import { getPermissions, getUser } from "../common/utils";
import Close from "@carbon/icons-react/lib/close/32";
import SharingInfo from "../components/SharingInfo";
import { isShared } from "../common/utils";

export default class UpdateModel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            model: this.props.model,
        }
        this.sharing = []
        this.desc = React.createRef(this.props.model.object.description);
        this.ver = React.createRef(this.props.model.object.version);
        this.name = React.createRef(this.props.model.object.name);

    }

    renderLabelEncodings = (model) => {
        let rows = [];

        if (model.object.learner.label_encodings === undefined) {
            return rows;
        }
        var i = 0;
        for (const value of model.object.learner.label_encodings) {
            const k = i;
            rows.push(<TableRow key={"row_" + i}>
                <TableCell>{k}</TableCell>
                <TableCell>{value}</TableCell>
            </TableRow>);
            i += 1;
        }


        return rows;
    }

    renderSchemaRow() {

        if (this.state.model.object.learner.name == "sentiments") {
            return (<div />)
        } else return (<StructuredListRow>

            <StructuredListCell>

                <h4>Label encoding</h4>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeader>Model output</TableHeader>
                            <TableHeader>Label</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.renderLabelEncodings(this.state.model)
                        }
                    </TableBody>
                </Table>


            </StructuredListCell>
            <StructuredListCell>
                <h4>Schema</h4>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeader>Feature name</TableHeader>
                            <TableHeader>Type</TableHeader>
                            <TableHeader>Role</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.schema(this.state.model)
                        }
                    </TableBody>
                </Table>

            </StructuredListCell>
        </StructuredListRow>)
    }

    version = () => {
        const v = this.state.model.object.version;
        if (v == undefined || v === "") {
            return "0.0.1"
        }
        return v;
    }

    schema() {
        if (this.state.model.object.input_schema != null
            && this.state.model.object.input_schema.fields != null) {
            return Object.entries(this.state.model.object.input_schema.fields).map((m, i) => {
                return <TableRow key={"row_" + i}>
                    <TableCell>{m[1]['name']}</TableCell>
                    <TableCell>{m[1]['data_type']}</TableCell>
                    <TableCell>{m[1]['role']}</TableCell>
                </TableRow>
            })
        }

    }

    idxToPermission = (i) => {
        switch (i) {
            case 0:
                return "manage"
            case 1:
                return "approve"
            case 2:
                return "move"
            case 3:
                return "enable"
        }

        return ""
    }

    toApiSharing = () => {
        return this.sharing.map(s => {
            let permissions = []
            s.perms.forEach((p, i) => {
                if (p) {
                    permissions.push(this.idxToPermission(i))
                }
            })
            return {
                user: s.user,
                permissions: permissions
            }
        })
    }

    onOk = () => {
        this.props.saveModel({
            name: this.name.current.value,
            version: this.ver.current.value,
            description: this.desc.current.value,
            sharing: this.toApiSharing()
        })
    }

    renderMetricsCell() {
        if (this.state.model.object.metrics.measurements != null) {
            return (<StructuredListCell>
                <h4>Training metrics</h4>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeader>Metric</TableHeader>
                            <TableHeader>Value</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.entries(this.state.model.object.metrics.measurements).map((m, i) => {
                                return <TableRow key={"row_" + i}>
                                    <TableCell>{m[0]}</TableCell>
                                    <TableCell>{m[1]}</TableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </StructuredListCell>)
        } else {
            return (<br />)
        }
    }

    permUpdated = (sharing) => {
        this.sharing = sharing
    }

    renderUserSharing = () => {

        if (!this.canManage()) {
            return <div />
        }

        return <div>
            <h4>Sharing</h4>

            <SharingInfo
                className="mlp-model-sharing-box"
                system={this.state.model.system}
                sharing={this.state.model.object.sharing}
                permissionsLabels={["Manage", "Approve", "Move", "Enable"]}
                permissions={["manage", "approve", "move", "enable"]}
                onUpdate={this.permUpdated} />

        </div>
    }

    canManage = () => {
        let perms = getPermissions()
        if (!(perms.admin || perms.manage_models)) {
            return false;
        }
        return true
    }

    render() {

        return <div>
            <div className="mlp-model-update-close">
                <Close className="mlp-clickable" onClick={this.props.close} />
            </div>
            <div className="mlp_ops_exp_panel1">
                <StructuredListWrapper>
                    <StructuredListBody>
                        <StructuredListRow>
                            <StructuredListCell>
                                <div className="text_field">
                                    {
                                        <TextInput
                                            id="model_name"
                                            labelText="Name"
                                            type="text"
                                            disabled={!this.canManage()}
                                            defaultValue={this.state.model.object.name}
                                            ref={this.name}
                                        />
                                    }
                                </div>

                                <div className="text_field">
                                    <TextInput
                                        id="model_ver"
                                        labelText="Version"
                                        type="text"
                                        disabled={!this.canManage()}
                                        defaultValue={this.state.model.object.version}
                                        ref={this.ver}
                                    />
                                </div>
                            </StructuredListCell>
                            <StructuredListCell>
                                <div className="mlp_ops_exp_panel2">

                                    <TextArea
                                        id="model_desc"
                                        labelText="Description"
                                        type="text"
                                        disabled={!this.canManage()}
                                        defaultValue={this.state.model.object.description}
                                        ref={this.desc}
                                    />

                                </div>
                            </StructuredListCell>
                        </StructuredListRow>
                        <StructuredListRow>
                            <StructuredListCell>
                                <h4>Training</h4>

                                <p><b className="mlp_text_bold">Name:</b> {this.state.model.object.learner.name}</p>
                                <p><b className="mlp_text_bold">Version:</b> {this.version()}</p>
                                <p><b className="mlp_text_bold">Type:</b> {this.state.model.object.learner.problem_type}
                                </p>
                                <p><b className="mlp_text_bold">Target field:</b> {this.state.model.object.target}</p>
                                <p><b className="mlp_text_bold">Data
                                    sampling:</b> {this.state.model.object.build.data_sampling}</p>
                                <p><b className="mlp_text_bold">Scope:</b> {this.state.model.object.build.scope}</p>
                                {
                                    (isShared(this.state.model)) ?
                                        <p><b className="mlp_text_bold">Shared by:</b> {this.state.model.system.author}</p> :
                                        <div />
                                }
                                {
                                    (this.props.job !== undefined) ?
                                        <p><b className="mlp_text_bold">Journey:</b> {this.props.job.class}</p> :
                                        <div />
                                }
                                {
                                    (this.props.job !== undefined) ?
                                        <p><b className="mlp_text_bold">Job:</b> {this.props.job.name}</p> :
                                        <div />
                                }
                            </StructuredListCell>

                            {this.renderMetricsCell()}
                        </StructuredListRow>


                        {this.renderSchemaRow()}

                        <StructuredListRow>
                            <StructuredListCell></StructuredListCell>
                            <StructuredListCell>

                                {this.renderUserSharing()}
                            </StructuredListCell>
                        </StructuredListRow>

                    </StructuredListBody>
                </StructuredListWrapper>


            </div>

            <div className="mlp_save_cancel_buttons">

                <Button kind={"primary"} disabled={!this.canManage()} onClick={this.onOk}><p>Save</p></Button>

                <Button kind={"secondary"} onClick={this.props.close}><p>Cancel</p></Button>
            </div>
        </div>
    }
}