import React from "react";
import Datasets from "../datasets/DataSets";
import MLPTextWithMore from "../components/TextWithMore";

export default class MLPRiskDataSets extends React.Component {

    render() {
        return (
            <div className={"mlp_datasets_description"}>
                <div className={"mlp_datasets_header"}>
                    <br />
                    <h3 className="mlp_size_xlarge mlp_no_padding mlp_center">Choose or define a data set for Risk Analysis journey</h3>
                    <br /><br />
                    <div className={"mlp_center"}>
                        <MLPTextWithMore
                            above={<h6>You can select a predefined dataset or define your own dataset in order
                                perform risk analysis. Expand to see how your data should look like in order to have
                                a successful insight.</h6>}>
                            <p>In order to have a successful risk analysis your data should include a <b
                                className={"mlp_text_bold"}>target</b>(risk) role for one of the fields from the
                                selected datasource.
                                <br />The <b className={"mlp_text_bold"}>target</b> role should refer a field with
                                discrete values (0 and 1).</p>
                        </MLPTextWithMore>
                    </div>
                    <br />
                    <Datasets onDataSet={this.props.onDataSet} hideControls={true} />

                </div>
            </div>
        )
    }
}