import React from "react";
import {mlp_get, SERVER_API_URL} from "../index";
import {Loading} from "@carbon/react";
import DataFields from "../datasets/DataFields";
import JobSharing from "../components/JobSharing";
import {Meta} from "./Meta";
import {ViewAdapter} from "./ViewAdapter";


export default class CaseView extends React.Component {

    dataSourceId = undefined;
    state = {
        ready: false
    };
    meta = new Meta()
    resourceView = new ViewAdapter()


    setDataSet(dsId) {
        const pathToFetch = SERVER_API_URL + '/v1/datasets/' + dsId
        mlp_get(pathToFetch)
            .then(jsonData => {
                if (jsonData.code == undefined) {
                    this.meta.setDataSource(jsonData)
                    this.dataSourceId = dsId
                }
                this.setState({
                    ready: true
                });
            })
    }

    showSharingInfo() {
        return (this.state.ready) ? <JobSharing meta={this.meta}/> : <b/>
    }

    renderDatasetInfo() {
        return (this.dataSourceId === undefined) ?
            (<p>Datasource information is missing. The data dataset for this scenario might have been deleted.</p>) :
            (<DataFields caseMeta={this.meta} 
                            roles={this.meta.SUPPORTED_ROLES}
                            readOnly={true}
                            description={""}/>)
    }

    renderDatasetTitle() {
        return this.dataSourceId === undefined ? "Scenario input data" : "Scenario input data: " + this.meta.dataSourceJson.object.name
    }

    render() {
        if (this.state.ready) {
            return this.renderContent()
        } else {
            const pathToFetch = SERVER_API_URL + '/v1/jobs/' + this.props.id
            mlp_get(pathToFetch)
                .then(jsonData => {
                    this.meta.setJob(jsonData)
                    return jsonData.object.dataset_id;
                }).then((dsId) => {
                this.setDataSet(dsId)
                return this.meta.jobId()
            }).then((jobId) => this.resourceView.monitorJob(jobId, this.meta.setJob).then(_ => this.forceUpdate()))
            return (<div><Loading description="Loading journey information" withOverlay={false}/></div>);
        }
    }
}